import React, {useEffect, useState} from 'react';



function ReviewTest() {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch('http://localhost:8080/all')  // Correct backend URL
          .then(response => {
            console.log('Response:', response); // Log the entire response

        if (!response.ok) {
          console.error('Network response was not ok', response.statusText);
          throw new Error('Network response was not ok');
        }

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new TypeError("Expected JSON, but got something else");
        }
            return response.json();
          })
          .then(data => setUsers(data))
          .catch(error => console.error('Error fetching users:', error));
      }, []);

    return (
        <div>
            <ul>
        {users.map(user => (
          <li key={user["id"]}>{user["reviewContent"]}</li>
        ))}
      </ul>
        </div>
    )

}

export default ReviewTest;