import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Header from './header';
import Footer from './footer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import tv_1 from '../pdfFiles/pdf1.pdf';
import tv_2 from '../pdfFiles/pdf2.pdf';
import hood from '../pdfFiles/pdf3.pdf';
import dishwasher from '../pdfFiles/pdf4.pdf';
import oven from '../pdfFiles/pdf5.pdf';
import mirror from '../pdfFiles/mirror.pdf';
import toilet from '../pdfFiles/toilet.pdf';






function HomeBookPage() {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
}, []);


    const headerFontSize = isSmDown ? "3rem" : "4rem";
    const leftPadding = isSmDown ? 5 : 20;
    return (
        <Container disableGutters maxWidth={false} sx={{minHeight: "auto", bgcolor: "grey", maxWidth: "100%"}}>
            <Container disableGutters maxWidth={false} sx={{width: "inherit", backgroundColor:`#DFDFDF`, minHeight: "auto", display: "flex", flexDirection: "column"}}>
            <Header/>
            <Box sx={{width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: headerFontSize, fontWeight: 400, textTransform: "uppercase", pt: 5,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center", borderBottom: 5, borderColor: "#001524"}}>
                {t("homebook")}
            </Box>
            <Stack>
                <Box sx={{textAlign: "center", width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: "2rem", fontWeight: 400, pt: 10,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
                    {t("homebook_1")}
                </Box>
                <Box sx={{textAlign: "center", width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: "2rem", fontWeight: 400, pt: 5,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
                    {t("homebook_2")}
                </Box>
            </Stack>
            <Box sx={{pb: 10, pt: 8, pl: leftPadding, minWidth: "auto"}}>
                        <Stack spacing={0} sx={{width: "80%"}}>
                            <Box sx={{pt: 2}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "3rem", fontWeight: 600, textTransform: "uppercase"}}>{t("1st_floor")}</Typography>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderTop: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("oven_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={oven} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("dishwasher_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={dishwasher} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("hood_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={hood} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("tv_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={tv_2} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>


                            <Box sx={{pt: 2}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "3rem", fontWeight: 600, textTransform: "uppercase"}}>{t("2nd_floor")}</Typography>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderTop: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("tv_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={tv_2} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("speakersmirror_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={mirror} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 2}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "3rem", fontWeight: 600, textTransform: "uppercase"}}>{t("3rd_floor")}</Typography>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderTop: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("tv_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={tv_2} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("mirror_pdf")}</Typography>
                                {/* <Button disableFocusRipple disableElevation  size="small" variant="contained" href={oven} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button> */}
                            </Box>
                            <Box sx={{pt: 1, pb: 1, borderBottom: 1, borderColor: "#15616D", display:"flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
                                <Typography sx={{fontFamily: "Cormorant Garamond", color: "#15616D", fontSize: "1.5rem", fontWeight: 500, textTransform: "uppercase"}}>{t("toilet_pdf")}</Typography>
                                <Button disableFocusRipple disableElevation  size="small" variant="contained" href={toilet} target="_blank" sx={{fontFamily: "Cormorant Garamond", fontSize: "1rem", fontWeight: 900, backgroundColor: "#6F1F04", '&:hover': {backgroundColor: '#6F1F04',boxShadow: 'none'}}}>
                                    PDF
                                </Button>
                            </Box>
                            
                        </Stack>
            </Box>
          
          <Footer/>
          </Container>
        </Container>
    );
};

export default HomeBookPage;