import React, {useEffect, useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';


function PriceTable() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
    }, []);

    const fontSize1 = isSmDown ? "1.5rem" : "1.75rem";
    const fontSize2 = isSmDown ? "1.25rem" : "1.5rem";
    const padding = isSmDown ? 1 : 2;


    return (
        <Box sx={{width: "90%", pt: 10, display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
            <Grid container spacing={0} sx={{borderColor: "#001524", border: 2}}>
                <Grid item xs={12} sx={{p: 2, bgcolor: "#001524", fontSize: "2rem", fontFamily: "Cormorant Garamond", color: "white", fontWeight: 600, textTransform: "uppercase",  display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    {t("prices")}
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, borderRight: 2, fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "black", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    {t("2p")}
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    <Typography sx={{fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "#6F1F04", fontWeight: 400,}}>
                    {t("2_price")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, borderRight: 2, fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "black", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    {t("4p")}
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                <Typography sx={{fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "#6F1F04", fontWeight: 400,}}>
                    {t("4_price")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, borderRight: 2, fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "black", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    {t("6p")}
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                <Typography sx={{fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "#6F1F04", fontWeight: 400,}}>
                    {t("6_price")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, borderRight: 2, fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "black", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    {t("8p")}
                </Grid>
                <Grid item xs={6} sx={{p: padding, borderColor: "#001524", borderBottom: 2, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                <Typography sx={{fontSize: fontSize1, fontFamily: "Cormorant Garamond", color: "#6F1F04", fontWeight: 400,}}>
                    {t("8_price")}
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{bgcolor: "#001524", fontSize: fontSize2, fontFamily: "Cormorant Garamond", color: "white", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    <Stack spacing={0} sx={{p: 2}}>
                        <Box sx={{textAlign: "center"}}>
                                {t("one_time_cleaning")} 
                                {t("cleaning_fee1")}
                            
                            
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: 'center', textAlign: "center"}}>
                            {t("stay1")} 
                        </Box>
                        <Box sx={{pt: 2, textAlign: "center"}}>
                            {t("additional_cleaning")} 
                            {t("cleaning_fee2")}
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: 'center', textAlign: "center"}}>
                            {t("stay2")} 
                        </Box>

                    </Stack>

                </Grid>
                <Grid item xs={5} sx={{bgcolor: "#001524", fontSize: "5rem", color: "white", display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    <CleaningServicesIcon fontSize="inherit"/>
                </Grid>
                <Grid item xs={12} sx={{fontStyle: "italic", fontSize: fontSize2, fontFamily: "Cormorant Garamond", color: "#6F1F04", fontWeight: 400, display: "flex", alignItems: "center", justifyContent: 'center', p: 2, textAlign: "center"}}>
                    {t("price_table_end")}
                </Grid>
                

            </Grid>
        </Box>
    )

}

export default PriceTable;

