import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Header from './header';
import Footer from './footer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import dayjs, { Dayjs } from 'dayjs';
//import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import styled from '@emotion/styled';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateField } from '@mui/x-date-pickers/DateField';
import 'dayjs/locale/lt';
import 'dayjs/locale/en';
import CircleIcon from '@mui/icons-material/Circle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import { addDays, isSameDay } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { enUS, lt } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import "./styles.css";
import Button from '@mui/material/Button';
import axios from 'axios';
import emailjs from "@emailjs/browser";
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import PriceTable from './priceTable';

const people = [2, 3, 4, 5, 6, 7, 8];




function Booking() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    //const [value, setValue] = React.useState<Dayjs | null>(dayjs());
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [selectingStart, setSelectingStart] = useState(true);
    const [value, setValue] = React.useState<Dayjs | null>(dayjs());
    const [minDate, setMinDate] = React.useState<Date>(new Date());
    const [reservingPairs, setReservingPairs] = useState<Date[][]>([]);
    const [reservedPairs, setReservedPairs] = useState<Date[][]>([]);
    const [disabledDates, setDisabledDates] = useState<Date[]>([]);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [data1, setData1] = useState(false);
    const [data2, setData2] = useState(false);
    const [error, setError] = useState(false);



    useEffect(() => emailjs.init("q5ashSwMKv0rwxZfg"), []);
  // Add these
    const handleEmail = async () => {
        const serviceId = "service_f0z63kd";
        const templateId = "template_3h7emfs";
    try {
      await emailjs.send(serviceId, templateId, {
        startDate: formData.startDate,
        endDate: formData.endDate,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        numPeople: formData.numPeople,
        telephone: formData.telephone,
        notes: formData.notes
      });
      console.log("email successfully sent check inbox");
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } 
  };

    const [formData, setFormData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        numPeople: 2,
        telephone: "",
        notes: "",
        startDate: "",
        endDate: ""
    })

    // let reservingPairs: Date[][] = []
    // let reservedPairs: Date[][] = []

    const addToReservingPairs = (arr: any) => {
        const newPairs: Date[][] = [];
        setDisabledDates([]);
        for (let i = 0; i < arr.length; i++) {
            const dateRange = arr[i];
            const start = dayjs(dateRange.check_in, "YYYY-MM-DD").toDate();
            const end = dayjs(dateRange.check_out, "YYYY-MM-DD").toDate();
            // if (!pairExists(reservingPairs, [start, end])) {
            //     newPairs.push([start, end]);
            // }
            newPairs.push([start, end]);
            const datesInRange = getDatesInRange(start, end);
            setDisabledDates(prevDates => [...prevDates, ...datesInRange]);
        }
        setReservingPairs(newPairs);

    } 

    const addToReservedPairs = (arr: any) => {
        const newPairs: Date[][] = [];
        for (let i = 0; i < arr.length; i++) {
            const dateRange = arr[i];
            const start = dayjs(dateRange.check_in, "YYYY-MM-DD").toDate();
            const end = dayjs(dateRange.check_out, "YYYY-MM-DD").toDate();
            // if (!pairExists(reservedPairs, [start, end])) {
            //     newPairs.push([start, end]);
            // }
            newPairs.push([start, end]);
            const datesInRange = getDatesInRange(start, end);
            setDisabledDates(prevDates => [...prevDates, ...datesInRange]);
        }
        setReservedPairs(newPairs);

    } 

    const pairExists = (pairsArray: Date[][], targetPair: Date[]) => {
        const [targetStart, targetEnd] = targetPair.map(normaliseDate);
      
        return pairsArray.some(([startDate, endDate]) => {
          const normalizedStart = normaliseDate(startDate);
          const normalizedEnd = normaliseDate(endDate);
        //   console.log(normalizedStart, targetStart)
        //   console.log(normalizedStart.toString() === targetStart.toString())
          return normalizedStart.toString() === targetStart.toString() && normalizedEnd.toString() === targetEnd.toString();
        });
      }

    const normaliseDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const handleSubmition = async () => {
        try {
            const response = await axios.post('https://nodeapi-fimqq3rnia-nw.a.run.app/api/reserve/add', null, {
                params: formData, 
            });
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    function getDatesInRange(startDate: Date, endDate: Date): Date[] {
        const dates: Date[] = [];
        let currentDate = new Date(startDate);
      
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
      
        return dates;
      }

    const fetchReserving = async () => {
        try {
            const response = await axios.get('https://nodeapi-fimqq3rnia-nw.a.run.app/api/reserve/dates', {
              params: {
                status: "reserving",
              },
            });
            addToReservingPairs(response.data);
          } catch (error) {
            console.error('Error fetching confirmed dates:', error);
          }

          //console.log(reservingPairs)
    }

    const fetchReserved = async () => {
        try {
            const response = await axios.get('https://nodeapi-fimqq3rnia-nw.a.run.app/api/reserve/dates', {
              params: {
                status: "reserved",
              },
            });
            //console.log(response.data);
            addToReservedPairs(response.data);
          } catch (error) {
            console.error('Error fetching confirmed dates:', error);
          }

          //console.log(reservedPairs)
    }

    // useEffect(() => {
    //     setLoading(true);
    //     fetchReserving();
    //     setTimeout(() => {
    //         console.log(loading)
    //       }, 5000);
    //     fetchReserved();
    //     setLoading(false);
    //   }, []);

    //const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          setError(false);
          
          await fetchReserving();
          await fetchReserved();
            setLoading(false);
        
            
            
          
        };
    
        fetchData();
      }, [submitted]);

    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData({...formData, [e.target.name]: value});
    }

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
    }, []);

    const DayContent = (date: Date) => {
        for (let i = 0; i < reservingPairs.length; i++) {
            const dateRange = reservingPairs[i];
            const start = dateRange[0];
            const end = dateRange[1];
            if (normaliseDate(date) >= normaliseDate(start)
            && normaliseDate(date) <= normaliseDate(end) && normaliseDate(date) >= normaliseDate(minDate)) {
                return (
                    <div className="disabling-after-min">
                        {date.getDate()}
                    </div>
                )
            } 
        }

        for (let i = 0; i < reservedPairs.length; i++) {
            const dateRange = reservedPairs[i];
            const start = dateRange[0];
            const end = dateRange[1];
            if (normaliseDate(date) >= normaliseDate(start)
            && normaliseDate(date) <= normaliseDate(end) && normaliseDate(date) >= normaliseDate(minDate)) {
                return (
                    <div className="disabled-after-min">
                        {date.getDate()}
                    </div>
                )
            } 
        }

        return (
            <div>
              {date.getDate()}
            </div>
          );
    }

    

      const [state, setState] = useState<Range[]>([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);


      const [errors, setErrors] = useState({firstName: "",lastName: "",email: "", checkIn: "", checkOut: ""});

      function isValidEmail(email: string): boolean {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
        }

      const validate = () => {
        let tempErrors = {firstName: "",lastName: "",email: "", checkIn: "", checkOut: ""};
        tempErrors.firstName = formData.firstName !== "" ? '' : t("error");
        tempErrors.lastName = formData.lastName !== "" ? '' : t("error");
        tempErrors.email = formData.email !== "" && isValidEmail(formData.email) ? '' : t("error");
        tempErrors.checkIn = startDate !== null ? "" : t("error");
        tempErrors.checkOut = endDate !== null ? "" : t("error");
        setErrors(tempErrors);
      };


      const handleSubmit = (e: any) => {
        e.preventDefault();
        if (e.target.checkValidity() && isValidEmail(formData.email)) {
            let tempErrors = {firstName: "",lastName: "",email: "", checkIn: "", checkOut: ""};
            setErrors(tempErrors);
          console.log('Form submitted');
          formData.startDate = startDate === null ? "" : startDate.format('YYYY-MM-DD');
          formData.endDate = endDate === null ? "" : endDate.format('YYYY-MM-DD');
          handleSubmition();
          handleEmail();
          setFormData({firstName: "", lastName: "", email: "", numPeople: 2, telephone: "", notes: "", startDate: "", endDate: ""});
          setStartDate(null);
          setEndDate(null);
        } else {
          // Form is invalid
          validate();
          setSubmitted(false);
        //   fetchReserving();
        //   fetchReserved();
          console.log('Form has errors');
        }
      }
    

      const onDateChange = (item: RangeKeyDict) => {
        setState([item.selection]);
        setStartDate(dayjs(item.selection.startDate));
        setEndDate(dayjs(item.selection.endDate));
        // if (dayjs(item.selection.startDate).isSame(dayjs(item.selection.endDate))) {
        //     setStartDate(dayjs(item.selection.startDate));
        //     setEndDate(null)
        // } else {
        //     setStartDate(dayjs(item.selection.startDate));
        //     setEndDate(dayjs(item.selection.endDate));
        // }
        
      }
    
    const headerFontSize = isSmDown ? "3rem" : "4rem";
    const gridSplit = isSmDown ? 12 : 3;
    const gridLeftPadding = isSmDown ? 5 : 50;
    const circleWidth = isSmDown ? "300px" : "600px";
    const reserveFont = isSmDown ? "1.7rem" : "2rem";
    const textWidth = isSmDown ? "90%": "50%";
    const errorMarginOut = errors.checkOut === "" ? "0rem" : "1.1rem";
    const errorMarginIn = errors.checkIn === "" ? "0rem" : "1.1rem";


    return (
        <Container disableGutters maxWidth={false} sx={{minHeight: "auto"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <Container disableGutters maxWidth={false} sx={{width: "inherit", backgroundColor:`#DFDFDF`, minHeight: "auto", display: "flex", flexDirection: "column"}}>
                <Header/>
                <Box sx={{width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: headerFontSize, fontWeight: 400, textTransform: "uppercase", pt: 5,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center", borderBottom: 5, borderColor: "#001524"}}>
                    {t("reserve")}
                </Box>
                <PriceTable/>
                <Stack sx={{display: "flex", justifyContent: "center"}}>
                    <Box sx={{textAlign: "center", width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: reserveFont, fontWeight: 400, pt: 10,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
                        {t("reserve_1")}
                    </Box>
                    <Box sx={{textAlign: "center", width: "80%", fontFamily: "Cormorant Garamond", color: "black", fontSize: reserveFont, fontWeight: 400, pt: 0,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
                        {t("reserve_2")}
                    </Box>
                    <Box sx={{textAlign: "center", width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: "2rem", fontWeight: 400, pt: 5, pb: 5,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center"}}>
                        <Stack sx={{display: "flex", justifyContent: "center"}}>
                            <Box sx={{size: "20px", display: 'flex', justifyContent: "flex-start", alignItems: 'center', width: circleWidth}}>
                                <CircleIcon fontSize="inherit" sx={{color: "#67ff63"}}/>
                                <Typography align="left" sx={{fontFamily: "Cormorant Garamond", color: "black", fontSize: "1.2rem", fontWeight: 500, pl: 1, wordBreak: 'break-word',hyphens: 'manual'}}>{t("green")}</Typography>
                            </Box>
                            <Box sx={{size: "20px", display: 'flex', justifyContent: "flex-start", alignItems: 'center', width: circleWidth}}>
                                <CircleIcon fontSize="inherit" sx={{color: "#ff3d3d"}}/>
                                <Typography align="left" sx={{fontFamily: "Cormorant Garamond", color: "black", fontSize: "1.2rem", fontWeight: 500, pl: 1, wordBreak: 'break-word',hyphens: 'manual'}}>{t("red")}</Typography>
                            </Box>
                            <Box sx={{size: "20px", display: 'flex', justifyContent: "flex-start", alignItems: 'center', width: circleWidth}}>
                                <CircleIcon fontSize="inherit" sx={{color: "#ffff50"}}/>
                                <Typography align="left" sx={{fontFamily: "Cormorant Garamond", color: "black", fontSize: "1.2rem", fontWeight: 500, pl: 1, wordBreak: 'break-word',hyphens: 'manual'}}>{t("yellow")}</Typography>
                            </Box>

                        </Stack>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        {
                            loading ? (
                                <LoadingButton loading sx={{outline: 'none', '&:focus': { outline: 'none' }, fontSize: "10rem"}}/>
                            ) : (
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={onDateChange}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                    locale={i18n.language === "lt" ? lt : enUS}
                                    months={2}
                                    direction={isSmDown ? "vertical" : "horizontal"}
                                    minDate={new Date()}
                                    disabledDates={disabledDates}
                                    rangeColors={['#ffffff', '#3ecf8e', '#fed14c']}
                                    dayContentRenderer={DayContent}
                                    preventSnapRefocus={true}
                                />
                            )
                        }
                    
                    
                    </Box>
                    <Box onSubmit={handleSubmit} autoComplete="off" noValidate component="form" sx={{width: "100%", display: "flex", justifyContent:"center", flexDirection: "column", pt: 5}}>
                    <Box sx={{width: "90%", display: "flex", alignSelf:"center", pb: 5}}>
                        <Grid container sx={{width: "100%", direction: "row", justifyContent: "center", alignItems: "center"}}>
                            <Grid item xs={5} sx={{display: "flex", justifyContent: "flex-end", mt: errorMarginIn}}>
                                <DateField helperText={errors.checkIn} required format="YYYY-MM-DD" value={startDate} InputProps={{readOnly: true}} size="small" label={t("start_date")} sx={{fontFamily: "Cormorant Garamond", '& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},'& .MuiFormLabel-root': {fontSize: '1.2rem', fontFamily: "Cormorant Garamond", '&.Mui-focused': {color: '#001524', fontWeight: "bold"},},
                              }}/>
                            </Grid>
                            <Grid item xs={2} sx={{display: "flex", justifyContent: "center"}}>
                                <HorizontalRuleIcon sx={{color: "black"}}/>
                            </Grid>
                            <Grid item xs={5} sx={{display: "flex", justifyContent: "flex-start", mt: errorMarginOut}}>
                                <DateField helperText={errors.checkOut} required format="YYYY-MM-DD" value={endDate} InputProps={{readOnly: true}} size="small" label={t("end_date")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},'& .MuiFormLabel-root': {fontSize: '1.2rem', fontFamily: "Cormorant Garamond", '&.Mui-focused': {color: '#001524', fontWeight: "bold"},},}}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField error={errors.firstName !== ''} helperText={errors.firstName} name="firstName" value={formData.firstName} onChange={handleChange} fullWidth required size="small" label={t("first_name")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                        '& .MuiFormLabel-root': {
                            fontFamily: "Cormorant Garamond", 
                            fontSize: '1.2rem', 
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#001524', 
                            fontWeight: 'bold',
                          },
                        }}/>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField error={errors.lastName !== ''} helperText={errors.lastName} name="lastName" value={formData.lastName} onChange={handleChange} fullWidth required size="small" label={t("last_name")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                    '& .MuiFormLabel-root': {
                        fontFamily: "Cormorant Garamond", 
                        fontSize: '1.2rem', 
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#001524', 
                        fontWeight: 'bold',
                      },}}/>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField error={errors.email !== ''} helperText={errors.email} name="email" value={formData.email} onChange={handleChange} fullWidth required size="small" label={t("email_title")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                    '& .MuiFormLabel-root': {
                        fontFamily: "Cormorant Garamond", 
                        fontSize: '1.2rem', 
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#001524', 
                        fontWeight: 'bold',
                      },}}/>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField name="numPeople" value={formData.numPeople} onChange={handleChange} required fullWidth size="small" select label={t("num_people")} defaultValue={2} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                        '& .MuiFormLabel-root': {
                            fontFamily: "Cormorant Garamond", 
                            fontSize: '1.2rem', 
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#001524', 
                            fontWeight: 'bold',
                          },}}
                        SelectProps={{MenuProps: {PaperProps: {sx: {backgroundColor: '#001524',},},} as MenuProps,}}>
                            {people.map((option) => (
                                <MenuItem key={option} value={option} sx={{'&.MuiMenuItem-root': {color: 'white',fontWeight: 600,bgcolor: '#001524','&:hover': {bgcolor: '#20323f',},'&.Mui-selected': {bgcolor: '#20323f', color: 'white'},},color: "white", fontWeight: 600, bgcolor: "#001524",}}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField name="telephone" value={formData.telephone} onChange={handleChange} fullWidth size="small" label={t("telephone")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                    '& .MuiFormLabel-root': {
                        fontFamily: "Cormorant Garamond", 
                        fontSize: '1.2rem', 
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#001524', 
                        fontWeight: 'bold',
                      },}}/>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <TextField multiline rows={3} name="notes" value={formData.notes} onChange={handleChange} fullWidth size="small" label={t("notes")} sx={{'& .MuiInputBase-root': {'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#001524', },},
                    '& .MuiFormLabel-root': {
                        fontFamily: "Cormorant Garamond", 
                        fontSize: '1.2rem', 
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#001524', 
                        fontWeight: 'bold',
                      },}}/>
                    </Box>
                    <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                        <Button type="submit" variant="contained" sx={{backgroundColor: '#001524', '&:hover': {backgroundColor: '#001524',}, fontFamily: 'Cormorant Garamond', fontSize: "1.2rem"}}>{t("submit")}</Button>
                    </Box>
                    </Box>
                    {
                        submitted ? (
                            <Box sx={{width: textWidth, display: "flex", alignSelf:"center", justifyContent: "center", pb: 5}}>
                                <Card sx={{bgcolor: "white", width: "300px", p: 3}}>
                                    <Typography sx={{fontFamily: "Cormorant Garamond", color: "green", fontSize: "1rem", fontWeight: 500,}} align="center">
                                        {t("confirmed_1")}
                                    </Typography>
                                    <Typography sx={{fontFamily: "Cormorant Garamond", color: "green", fontSize: "1rem", fontWeight: 500,}} align="center">
                                        {t("confirmed_2")}
                                    </Typography>
                                </Card>
                            </Box>
                        ) : undefined
                    }
                    
                    
                    
                </Stack>
            </Container>
            <Footer/>
            </LocalizationProvider>
        </Container>
    )

}

export default Booking;

{/* <Grid item xs={gridSplit}>
                        {/* <DateCalendar disablePast value={endDate} onChange={onEndDateChange} shouldDisableDate={disableDate} sx={{'& .MuiPickersDay-root': {'&:hover': {backgroundColor: '#ffffff'}, '&.Mui-selected': {backgroundColor: '#ffffff !important', color: '#000000'},},
                        '& .MuiPickersCalendarHeader-label': {
                            fontFamily: 'Cormorant Garamond', 
                            fontSize: '1.2rem', 
                          },
                          '& .MuiPickersYear-yearButton': {
                            color: '#000000', 
                            '&:hover': {
                              backgroundColor: '#20323f', 
                              color: '#ffffff', 
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#001524', 
                              color: '#ffffff', 
                            },
                          },
                        }} 
                            slots={{ day: (props) => {
                                        const { day, disabled } = props;
                                        const isPastDate = day.isBefore(dayjs());
                                        const isCustomDisabled = disableDate(day);
                    
                                        return (
                                            <CustomPickersDay
                                                {...props}
                                                isPastDate={!!(isPastDate && disabled)}
                                                isCustomDisabled={!!(isCustomDisabled && disabled)}/>);},}}/> */}
     //               </Grid> 


    //  const onStartDateChange = (newStart: Dayjs) => {
    //     for (let i = 0; i < takenDates.length; i++) {
    //         const dateRange = takenDates[i];
    //         const startRange = dateRange[0];
    //         const endRange = dateRange[1];
    //         if (newStart.isBefore(startRange) && endDate?.isAfter(endRange)) {
    //             setEndDate(null);
    //             setStartDate(null);
    //             return;
    //         }
    //     }
    //     if (newStart.isSame(endDate) || newStart.isAfter(endDate)) {
    //         setStartDate(newStart)
    //         setEndDate(null);
    //     } else {
    //         setStartDate(newStart);
    //     }
        
    // }
    
    // const onEndDateChange = (newEnd: Dayjs) => {
    //     for (let i = 0; i < takenDates.length; i++) {
    //         const dateRange = takenDates[i];
    //         const startRange = dateRange[0];
    //         const endRange = dateRange[1];
    //         if (startDate?.isBefore(startRange) && newEnd.isAfter(endRange)) {
    //             setEndDate(null);
    //             setStartDate(null);
    //             return;
    //         }
    //     }
    //     if (newEnd.isSame(startDate) || newEnd.isBefore(startDate)) {
    //         setEndDate(newEnd);
    //         setStartDate(null);
    //     } else {
    //         setEndDate(newEnd);
    //     }
        
    // }



    // const disableDate = (currentDate: Dayjs) => {
    //     for (let i = 0; i < takenDates.length; i++) {
    //         const dateRange = takenDates[i];
    //         const startRange = dateRange[0];
    //         const endRange = dateRange[1];
    //         if (currentDate.isSame(startRange) || currentDate.isSame(endRange)) {
    //             return true;
    //         } else if (currentDate.isAfter(startRange) && currentDate.isBefore(endRange)) {
    //             return true;
    //         } 
    //     }
    //     return false;
    // }


    // const CustomPickersDay = styled(PickersDay, {
    //     shouldForwardProp: (prop) =>
    //       prop !== 'isPastDate' && prop !== 'isCustomDisabled',
    // })<PickersDayProps<Dayjs> & { isPastDate: boolean; isCustomDisabled: boolean }>(
    //     ({ isPastDate, isCustomDisabled }) => ({
    //         ...(isPastDate && {
    //             backgroundColor: '#cccccc', // Red background for past disabled dates #cccccc
    //     }),
    //         ...(isCustomDisabled && {
    //             backgroundColor: '#ff3d3d', // Grey background for custom disabled dates
    //     }),
    //         ...(!isPastDate && !isCustomDisabled && {
    //             backgroundColor: '#67ff63', // Green background for non-disabled dates
    //         }),
    //     })
    // );


    // "start": "react-scripts start",