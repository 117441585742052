import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from '@mui/material/Container';
import Header from './header';
import Footer from './footer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material/styles";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import pic1 from '../pictures/1.jpg';
import pic2 from "../pictures/2.jpg";
import pic3 from "../pictures/3.jpg";
import pic4 from '../pictures/4.jpg';
import pic5 from "../pictures/5.jpg";
import pic6 from "../pictures/6.jpg";
import pic7 from '../pictures/7.jpg';
import pic8 from "../pictures/8.jpg";
import pic9 from "../pictures/9.jpg";
import pic10 from '../pictures/10.jpg';
import pic11 from "../pictures/11.jpg";
import pic12 from "../pictures/12.jpg";
import pic13 from '../pictures/13.jpg';
import pic14 from "../pictures/14.jpg";
import pic15 from "../pictures/15.jpg";
import pic16 from '../pictures/16.jpg';
//import pic17 from "../pictures/17.jpg";
import pic18 from "../pictures/18.jpg";
import pic19 from '../pictures/19.jpg';
import pic20 from "../pictures/20.jpg";
import pic21 from "../pictures/21.jpg";
import pic22 from '../pictures/22.jpg';
import pic23 from "../pictures/23.jpg";

import pic24 from "../pictures/24.jpg";
import pic25 from "../pictures/25.jpg";
import pic26 from "../pictures/26.jpg";
import pic27 from "../pictures/27.jpg";
import pic28 from "../pictures/28.jpg";
import pic29 from "../pictures/29.jpg";
import pic30 from "../pictures/30.jpg";
import pic31 from "../pictures/31.jpg";
import pic32 from "../pictures/32.jpg";
import pic33 from "../pictures/33.jpg";
import pic34 from "../pictures/34.jpg";
import pic35 from "../pictures/35.jpg";
import pic36 from "../pictures/36.jpg";
import pic37 from "../pictures/37.jpg";
import pic38 from "../pictures/38.jpg";
import pic39 from "../pictures/39.jpg";
//import pic40 from "../pictures/40.jpg";
import pic41 from "../pictures/41.jpg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const pictures = {pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12, pic13, pic14, pic15, pic16, pic32, pic18, pic19, pic20, pic21, pic22, pic23, pic24, pic25, pic26, pic27, pic28, pic29, pic30, pic31, pic33, pic34, pic35, pic36, pic37, pic38, pic39, pic41};




function PhotosPage() {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = 39;

    useEffect(() => {
      const storedLanguage = localStorage.getItem('language');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % (maxSteps));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return maxSteps - 1;
      } else {
        return prevActiveStep - 1
      }
      
    });
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };


  const [checked, setChecked] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (index: number) => {
    setActiveStep(index)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);







    const headerFontSize = isSmDown ? "3rem" : "4rem";
    const photoWidth = isSmDown ? "400px" : "600px";
    const photoHeight = isSmDown ? "500px" : "700px";
    const modalWidth = isSmDown ? "400px" : "1000px";
    const modalImageHeight = isSmDown ? "300px" : "700px";
    const modalImageWidth = isSmDown ? "270px" : "900px";
    const numCols = isSmDown ? 2 : 4;
    return (
        <Container disableGutters maxWidth={false} sx={{minHeight: "auto", bgcolor: "grey", maxWidth: "100%"}}>
          <Container disableGutters maxWidth={false} sx={{width: "inherit", backgroundColor:`#DFDFDF`, minHeight: "auto", display: "flex", flexDirection: "column"}}>
          <Header/>
          <Box sx={{width: "90%", fontFamily: "Cormorant Garamond", color: "black", fontSize: headerFontSize, fontWeight: 400, textTransform: "uppercase", pt: 5,  display: "flex", alignItems: "center", justifyContent: 'center', alignSelf:"center", borderBottom: 5, borderColor: "#001524"}}>
                        {t("photos")}
          </Box>
            <Box sx={{pt: 10, pb: 0}}>
              <Grid container spacing={2}>
                <Grid item xs={isSmDown ? 12 : 6} sx={{display: "flex", justifyContent: "center"}}>
                  <Box sx={{width: photoWidth, height: photoHeight}}>
                      <img
                            alt=""
                            width="100%"
                            height="100%"
                            src={pic29}
                            loading="lazy"
                            style={{ objectFit: 'cover' }}
                        />
                  </Box>
                </Grid>
                <Grid item xs={isSmDown ? 12 : 6} sx={{display: "flex", justifyContent: "center"}}>
                  <Box sx={{width: photoWidth, height: photoHeight}}>
                  <img
                            alt=""
                            width="100%"
                            height="100%"
                            src={pic30}
                            loading="lazy"
                            style={{ objectFit: 'cover' }}
                        />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
          </Box>
          <Box sx={{pt: 0, pb: 10, display: "flex", justifyContent: "center", minHeight: "auto"}}>
              
              <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" sx={{display: "flex", justifyContent: "center", alignItems: "center", '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }}}>
              <Box sx={{width: modalWidth, height: modalImageHeight, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", overflow: "hidden"}}>
              <Button sx={{fontSize: "5rem", color: "white", '&.MuiButton-root:hover':{bgcolor: 'transparent'}}} onClick={handleBack} disableRipple>
                <KeyboardArrowLeft fontSize="inherit"/>
              </Button>
              <AutoPlaySwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents interval={3000}>
              {Object.values(pictures).map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box component="img" sx={{maxWidth: '100%',
                    maxHeight: '100%', height: modalImageHeight, display: 'block', overflow: 'hidden', width: modalImageWidth, objectFit: "contain"}} src={step} />
                    ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <Button sx={{fontSize: "5rem", color: "white", '&.MuiButton-root:hover':{bgcolor: 'transparent'}}} onClick={handleNext} disableRipple >
                <KeyboardArrowRight fontSize="inherit"/>
              </Button>
              </Box>
            </Modal>
            
          </Box>
         
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", pt: 5, pb: 5, pl: 2, pr: 2}}>
          <ImageList variant="masonry" cols={numCols} gap={8}>
          {Object.values(pictures).map((url, index) => (
          <ImageListItem key={index}>
            <img
              alt=""
              src={url}
              loading="lazy"
              onClick={() => handleOpen(index)}
            />
          </ImageListItem>
        ))}
      </ImageList>
          </Box>
          <Footer/>
          </Container>
        </Container>
    );
};

export default PhotosPage;

